html,
body {
    max-width: 100% !important;
    overflow-x: hidden !important;
    background-color: #2B2B2B;
    font-family: 'Montserrat' !important;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

:-webkit-scrollbar {
    display: none;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');

.boxLogin {
    position: relative;
    background-color: rgba(51, 51, 51, .9);
    max-width: 450px;
    height: 340px;
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.contenedorImgLogin {

    display: flex;
    justify-content: center;

}

.img_logo{
    width: 250px;
}

.contenedorBtnsIni {
    position: relative;
    top: 25%;
}

.contBotInicio {
    position: relative;
    top: 10%;
    display: flex;
    justify-content: right;
    height: 45px;
}
.img_logo{
    width: 250px;
}
.img_logoIni{
    margin-top: 30px;
    width: 350px;
}
.contLogo{
    display: flex;
    justify-content: center;
}

/* Tabla */
.contenedorTabla {
    margin-top: 2.5%;
    margin-left: 0.1px;
    margin-right: 2px;
}

.contenedorTablaAnual {
    margin-top: 2.5%;
    padding-left: 10px;
}
.contPaginacion{
    display: flex;
    justify-content: center;
    text-align: center;
}
.pagination{
    color: #fff;
}
.txtMost{
    width: 160px;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px;
}

.contTabla {
    padding-right:  0px;
    padding-left: 0px;
}

.contTexto{
    background-color: #e7e7e7 !important;
    word-break:break-all;
}

.contTxtPag {
    display: flex;
    justify-content: center;
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.contBtnAnt {
    display: flex;
    justify-content: right;
    height: 25px;
    padding-right: 3px !important;
}

.contBtnSig {
    display: flex;
    justify-content: left;
    height: 25px;
    padding-left: 3px !important;
}

.btn,
.btnChico {
    --bs-btn-padding-y: 0rem;
    --bs-btn-padding-x: 0.15rem;
    --bs-btn-font-size: 12px !important;
    --bs-btn-border-radius: 0.08rem;
}


.txtPaginacion {
    padding-top: 5px;
    color: #fff;
    font-size: 12px;
}

.btnSig {
    font-size: 12px !important;
    color: #fff;
    background: #807977;
    opacity: 0.9;
    width: 15%;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    border-color: #807977;
    font-family: 'Montserrat' !important;
}

.colorIconoBtn {
    color: #fff;
}

.btnSig:hover {
    /* background-color: #d35321; */
    background-color: #5e5857;
    border-color: #5e5857;
}

.fechaTabla {
    font-size: 12px;
    font-weight: 300;
}

.varTabla {
    font-size: 10px;
    font-weight: 300;
}

.contMonto{
    background-color: #b6b6b6 !important;
    width: 200px !important;
    word-break:break-all;
}
.contFecha{
    background-color: #e7e7e7 !important;
    max-width: 70px !important;
    width: 70px !important;
}

.contenDes {
    background-color: #b6b6b6 !important;
    max-width: 70px !important;
    width: 70px !important;
}


.text-left {
    text-align: left !important;
}

table {

    min-width: max-content;
    border-collapse: separate;
    border-spacing: 0px;
}

table th {
    position: sticky;
    top: 0px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    outline: 1px solid #2B2B2B;
    border: 3px solid #2B2B2B;
}

table td {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    outline: 1px solid #2B2B2B;
    border: 3px solid #2B2B2B;
    cursor: pointer;
}

.leido{
    font-size: 12px;
    font-weight: 500;
}

.noLeido{
    font-size: 12px;
    font-weight: 700;
}


/* fin tabla */

.contTxt{
    height:300px;
    word-wrap: break-word;
    }
    
    .txtNot{
        font-size: 10px;
        color: #fff;
    }

@media(max-width:430px) {

    .btnLogin {
        font-size: 13px;
        width: 100%;
        height: 80%;
    }
}

.contDown{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.img_logoIni{
    margin-top: 30px;
    width: 350px;
}

.contLogo{
    display: flex;
    justify-content: center;
}


.botonesIni {
    width: 50%;
    height: 35px;
    border-radius: 40px !important;
    font-size: 17px !important;
  }
  .botonesReacti{
    width: 50%;
    height: 35px;
    border-radius: 40px !important;
    font-size: 17px;  
  }

@media(max-width:584px) {
    .leido{
        font-size: 12px;
    }
    
    .noLeido{
        font-size: 12px;
    }
    .contenDes {
        font-size: 12px;
    }

    .contMonto {
        font-size: 12px;
    }
    .contCant {
        font-size: 12px;
    }
    .boxLogin {
        height: 390px;
        margin-top: 25%;
    }
    .botonesIni{
        width: 60%;
    }
    .botonesReacti{
        width: 60%;
        font-size: 15px;
    }

}

.contTxt{
height:300px;
word-wrap: break-word;
}

.txtNot{
    font-size: 10px;
    color: #fff;
}