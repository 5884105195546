a:link,
a:visited,
a:active {
    position: relative;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 14px;
}

.form {
    margin: auto;
    width: 90%;
    padding: 1em .5em;
    text-align: center;
}

.form__title {
    font-size: 1.5rem;
    margin-bottom: .9em;
    color: #fff;
    text-align: left;
}

.form__title--registro {
    font-size: 1.5rem;
    margin-bottom: .5em;
    color: #fff;
    text-align: left;
}

.form__container {
    margin-top: .3em;
    display: grid;
    gap: .2em;
}

.form__containerDos {
    height: 150px;
    display: grid;
}

.form__group {
    position: relative;
    --color: #5757577e;
    margin-bottom: 8%;
}

.form__groupTres {
    position: relative;
    --color: #5757577e;
    margin-bottom: 6%;
}


.form__groupDos {
    position: relative;
    --color: #5757577e;
    margin-bottom: 4%;
}

.form__input {
    width: 100%;
    background: none;
    color: #fff;
    font-size: 1rem;
    padding: .6em .3em;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color);
    font-family: 'Montserrat' !important;
}

.form__input--fecha {
    width: 100%;
    background: none;
    color: #fff;
    font-size: 1rem;
    padding: .6em .3em;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--color);
    font-family: 'Montserrat' !important;
}



.form__input:focus+.form__label,
.form__input:not(:placeholder-shown)+.form__label {
    transform: translateY(-17px) scale(.9);
    transform-origin: left top;
    /* color: #00BCD4; */
    color: #E85217;
}

.form__label {
    font-size: 15px;
    color: #5C5C5C;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    transform: translateY(12px);
    transition: transform .3s, color .3s;
    font-family: 'Montserrat' !important;
}

.form__label--fecha {
    font-size: 15px;
    color: #5C5C5C;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    transform: translateY(-12px);
    font-family: 'Montserrat' !important;
}



.btnLogin:hover {
    /* background-color: #d35321; */
    background-color: #9e3309;
    border-color: #9e3309;
}

.btnLogin {
    color: #fff;
    background-color: #E85217 !important;
    border-radius: 0;
    background: #E85217;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    width: 45%;
    border-color: #E85217;
    font-family: 'Montserrat' !important;
    height: 89%;
}


.form__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    /* background-color: #00BCD4; */
    background-color: #E85217;
    transform: scale(0);
    transform: left bottom;
    transition: transform .4s;
}

.form__input:focus~.form__line,
.form__input:not(:placeholder-shown)~.form__line {
    transform: scale(1);
}



.form__errorReg {
    position: absolute;
    font-size: 12px;
    color: #ff0000;
    display: none;
}



.form__input:invalid~.form__line {
    background-color: #E85217;
}

.form__input:invalid[focused="true"] {
    border-bottom: 1px solid #ff0000;
}

.form__input:invalid[focused="true"]~.form__errorReg {
    display: block;
}